import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';


// import p1 from 'assests/Photos/Clubs/EcoClub/2024/Planting/1.jpg';
// import p2 from 'assests/Photos/Clubs/EcoClub/2024/Planting/2.jpg';
// import p3 from 'assests/Photos/Clubs/EcoClub/2024/Planting/3.jpg';
// import p4 from 'assests/Photos/Clubs/EcoClub/2024/Planting/4.jpg';
// import p5 from 'assests/Photos/Clubs/EcoClub/2024/Planting/5.jpg';
// import p6 from 'assests/Photos/Clubs/EcoClub/2024/Planting/6.jpg';


// import p7 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/7.jpg';
// import p8 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/8.jpg';
// import p9 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/9.jpg';
// import p10 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/10.jpg';
// import p11 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/11.jpg';
// import p12 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/12.jpg';
// import p13 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/13.jpg';
// import p14 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/14.jpg';
// import p15 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/15.jpg';
// import p16 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/16.jpg';
// import p17 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/17.jpg';
// import p18 from 'assests/Photos/Clubs/EcoClub/2024/EnvironmentDay/18.jpg';


import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';

import SideHealth from 'views/HealthAndWellnesClub/SideHealth';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import EcoClubSidebar2024 from '../EnvironmentDay2023/EcoClubSidebar2024';
import Sidebar from '../EcoClub/Sidebar';


const PlantingSeeds2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });


  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/association/EcoClub/Planting/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/association/EcoClub/Planting/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/association/EcoClub/Planting/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/association/EcoClub/Planting/4.webp`;
  const p5 = `${config.base_image_url}/home/events-activities/association/EcoClub/Planting/5.webp`;
  const p6 = `${config.base_image_url}/home/events-activities/association/EcoClub/Planting/6.webp`;


  const photos = [
    {
        src: p1,
        source: p1,
        rows: 1,
        cols: 1,
    },
    {
        src: p2,
        source: p2,
        rows: 1,
        cols: 1,
    },
    {
        src: p3,
        source: p3,
        rows: 1,
        cols: 1,
    },
    {
        src: p4,
        source: p4,
        rows: 1,
        cols: 1,
    }, {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
    },
    {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
    }, 
//     {
//         src: p7,
//         source: p7,
//         rows: 1,
//         cols: 1,
//     },
//     {
//         src: p8,
//         source: p8,
//         rows: 1,
//         cols: 1,
//     },
//     {
//         src: p9,
//         source: p9,
//         rows: 1,
//         cols: 1,
//     },
//     {
//       src: p10,
//       source: p10,
//       rows: 1,
//       cols: 1,
//     }, 
//     {
//     src: p11,
//     source: p11,
//     rows: 1,
//     cols: 1,
//    },
//    {
//     src: p12,
//     source: p12,
//     rows: 2,
//     cols: 1,
//    },
//    {
//     src: p13,
//     source: p13,
//     rows: 1,
//     cols: 1,
//    },
//    {
//     src: p14,
//     source: p14,
//     rows: 1.3,
//     cols: 2,
//    }, 
//    {
//     src: p15,
//     source: p15,
//     rows: 1.3,
//     cols: 2,
//    },
//    {
//     src: p16,
//     source: p16,
//     rows: 1.3,
//     cols: 2,
//    }, 
//    {
//     src: p17,
//     source: p17,
//     rows: 1,
//     cols: 1,
//     },

//       {
//         src: p18,
//         source:p18,
//         rows: 1,
//         cols: 1,
//       },
    
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      Planting Of Seeds
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 2  &  Date: 28 June 2024
        </Typography>
        {/* <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
"Time spent among trees is never time wasted. Let’s embark on a Journey into Nature"

        <br/>
        </Typography> */}
        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        As part of our Environmental Day awareness program, the students of Class 2 were engaged in an exciting journey into the world of planting seeds!  This initiative was designed to foster a connection with nature and introduce the basics of eco-friendly gardening to our young learners.
        <br></br>
        Students were encouraged to plant a variety of seeds in the garden of the school premises. They learned about different types of seeds and the steps involved in planting. This activity included practical demonstrations by giving an insight on seed growth and the significance of plants in our environment.
        <br></br>
        This seed planting activity was a successful part of our Environmental Day celebrations. It not only taught students how to plant seeds but also fostered a sense of curiosity and responsibility towards nature. This hands-on approach left a lasting impression on the students, inspiring them to nurture plants and appreciate the growth process for a better future.
       
       
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
"Planting seeds today grows the green guardians of tomorrow."

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <EcoClubSidebar2024 />
             </Box>
             <Box marginBottom={4}>
               <Sidebar />
             </Box>


    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default PlantingSeeds2024;